<template>
  <div >
    <p class="side-panel-text color-  ">
      <!-- @slot Content below the title -->
        <slot name="text">

          <p>  The list of potential referral sources can be very long.  Use this opportunity to determine which referral sources are important for your community and organization. </p>
          <br />
          <p> Click the X to delete an item from the list, and click and drag an item up or down to change the order of the list (hint - maybe put the most used ones at the top).</p>
          <br />
          <p> Those items with (quext) can't be edited or deleted but can be sorted for their display order.</p>
          <br />
          <p>  You can apply this list to your community only, to a selected list of multiple communities in your organization, or to all of the communities in your organization. </p>
        </slot>
    </p>
  </div>
</template>

<script>
  export default {
    name: "ReferralSourcesDescription",
    props: {
      /**
       * An entity name that is described inside the sidepanel
       */
      entityTitle: {
        type: String,
        default: '',
      },
      /**
       * If the sidepanel contains a description of the entity that can be viewed only
       */
      viewMode: {
        type: Boolean,
        default: false,
      },
      /**
       * If the sidepanel contains a description of the entity that will be created
       */
      newEntity: {
        type: Boolean,
        default: false,
      },
      /**
       * A name of the external source that is a creator of the entity described inside the sidepanel
       */
      externalSource: {
        type: String,
        default: '',
      },
    },
    
  }
</script>
<style scoped>
  .color-black{
    color:black;
  }
</style>