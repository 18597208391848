<template>
    <div class="no-scroll">
        <loader :loading="loading" :backdrop="true" />
        <div class="flex no-scroll list-container">
            <div  id="list" class="side-panel-text show-scroll w-1/2">
            <ul class="mt-6">
                <draggable
                    v-if="LeadSources"
                    :list="LeadSources"
                    item-key="display_order"
                    :sort="true"
                    @change="updateLeadSourcesOrder"
                   

                >
                    <template v-slot:item="{element: item}">
                        <div class="flex" v-if="item.status != 'deleted'">
                       
                            <span class="block truncate text-base font-400 boxed">
                            <div v-if="item.status == 'locked'" class="group">
                                <icon name="SixDots" class="h-4 w-4 text-white sort-position"></icon>
                                {{  item.display_name}}
                                <div v-if="item.quext_control" class="control-container">
                                     <span class="justify-right">(quext)</span>
                                </div>
                                <div v-else class="control-container">
                                     <icon
                                        name="edit"
                                        class="w-4 h-4 edit-button cursor-pointer opacity-0 group-hover:opacity-50"
                                        @click="editMode(item.community_lead_sources_id)"
                                        />
                                </div>
                                
                            </div>
                             <div v-else-if="item.status == 'locked-edited'" class="group" >
                                 <icon name="SixDots" class="h-4 w-4 text-white sort-position"></icon>
                                 {{  item.display_name}} 
                                <span class="new-marker">(edited)</span>
                                 <icon
                                    name="edit"
                                    class="w-4 h-4 edit-button cursor-pointer opacity-0 group-hover:opacity-50"
                                    @click="editMode(item.community_lead_sources_id)"
                                />
                            </div>
                            <div v-else-if="item.status == 'new-lead-locked'" class="group">
                                 <icon name="SixDots" class="h-4 w-4 text-white sort-position"></icon>
                                 {{  item.display_name}} 
                                  <icon
                                    name="edit"
                                    class="w-4 h-4 edit-button cursor-pointer opacity-0 group-hover:opacity-50"
                                    @click="editMode(item.community_lead_sources_id,true)"
                                />
                                <span class="new-marker">(new)</span>
                            </div>
                            <div v-else-if="item.status == 'unlocked'" >
                                 <icon name="SixDots" class="h-4 w-4 text-white sort-position"></icon>
                                <input type="text" class="form-input spacing-1-2 edit-text-box" v-model="LeadSources[getIndexById(item.community_lead_sources_id,'lead_source')].display_name" autocomplete="off" v-on:keyup.enter="saveLead(item)" />
                            </div>
                            <div v-else-if="item.status == 'new-lead'">
                                <input type="text" class="form-input spacing-1-2 new-text-box" :ref="'new-lead-'+item.display_order" :id="'new-lead-'+item.display_order" v-model="item.display_name" autocomplete="off" v-on:keyup.enter="finalizeNewLead(item.display_order)" />
                            </div> 
                            </span>
                        <icon v-if="!item.quext_control" name="closeCircle2" @click="removeLead(item.index)" class="h-5 w-5 text-white sort-close cursor-pointer"></icon>
                        </div>
                    </template>
                </draggable>
            </ul>
            </div>
            <div class="dropdown-container w-1/2">
                <div >
                     <form-label>Apply these changes to</form-label>
                     <dropdown  :options="ApplyToCommunity"  v-model="ActionOptions.applyToCommunities" class="w-1"></dropdown>
                </div>
                <br/>
                <div >
                     <form-label>Select specific communities</form-label>
                     <dropdown  :options="SpecificCommunities" v-model="ActionOptions.SpecificCommunities" :disabled="ActionOptions.applyToCommunities == 0 || ActionOptions.applyToCommunities == 1 "  class="w-2" :multiple="true" :searchable="true"></dropdown>
                </div>
                <br/>
                <div >
                     <form-label>Copy list from another community</form-label>
                     <dropdown  :options="CopyFromCommunitiesOptions" v-model="CopyFromCommunities"></dropdown>
                </div>
            </div>
            <br/>


        </div>
        <br/>
        <div class="flex">
        <span class="text-blue-500 text-sm cursor-pointer select-none mt-5" @click="addNewLead">
                    <icon  name="plus" class="w-5 h-5 inline-block mr-1"></icon>
                    <span class="mb-2">add new</span>
        </span> 
        <span class="text-blue-500 text-sm cursor-pointer select-none mt-5 reset-to-default" @click="resetDefault/* resetDefault */">
                 
                    <span class="mb-2">reset to default</span>
        </span>
        </div> 
        <modal-footer :footer="footer" :primary="save" :secondary="cancel"></modal-footer>
    </div>
</template>

<script>
import ConfirmationMixin from "@/mixins/ConfirmationMixin";
import {mapGetters,mapActions} from "vuex";
import Icon from '@/components/ui/Icon';
import draggable from 'vuedraggable';
import Dropdown from "@/components/ui/Dropdown";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import Loader from '@/components/ui/Loader'


export default {
    name:'ReferralSourcesList',
    components:{draggable,Icon,Dropdown,ModalFooter,Loader},
    mixins: [ConfirmationMixin],
    data(){
        return{
            LeadSources:[],
            DefaultLeadSources:[],
            ActionOptions:{
                applyToCommunities:0,
                SpecificCommunities:null,
                copiedFromOtherlist:false
            },
            CopyFromCommunities: null,
            ApplyToCommunity:[
                {
                    key:0,
                    value:'this community only'
                },
                {
                    key:1,
                    value:'all communities'
                },
                 {
                    key:2,
                    value:'select communities'
                }
            ],
            SpecificCommunities:[],
            CopyFromCommunitiesOptions:[],
            footer: {
                primaryButton: 'save',
                secondaryButton: 'cancel'
            },
            loading:false,
            confirmResetTDefault: false,
            community_ids : []

        }
    },
     watch:{
        'ActionOptions.applyToCommunities': function (newVal){
           if(newVal == 1 ){

               let communities = [];

               this.communities.forEach(community => {
                   communities.push(community.id)
               })
               this.ActionOptions.SpecificCommunities = communities


           }
        },
        'CopyFromCommunities': function (newVal){
            console.log(newVal)
            if(newVal != null){
             this.copyOtherCommunitiesLeadSources()
             this.ActionOptions.copiedFromOtherlist = true
            } 
        },
       
    },
    emits: ['cancel'],
     computed: {
        ...mapGetters( {
            communities: 'auth/communities',
            activeCommunity: 'auth/community',
        })
    },
    mounted() {
       
        this.communities.forEach(community => {
           
            this.SpecificCommunities.push(
                {
                        key: community.id,
                        value:community.name
                }
            )
            this.CopyFromCommunitiesOptions.push(
                    {
                        key: community.id,
                        value:community.name
                    }
                )
            this.community_ids.push(community.id)
            });
            this.loadCommunityLeadSources();
          
    },
    methods:{
        copyOtherCommunitiesLeadSources(){
            this.loading = true
            this.$guestCardDataProvider.getList('copyOtherCommunitiesLeadSources',{id: this.CopyFromCommunities}).then((response) => {
                    console.log(response);
                    this.LeadSources =  response
                     this.LeadSources.forEach((lead,index) => {
                         this.LeadSources[index].status = 'locked'
                    });

                    this.loading = false
                      
                })
        },
        resetDefault(){
            if (this.confirmResetTDefault) {
                    this.loadCommunityLeadSources
                } else {
                  this.requestConfirmation({
                    confirmationMessage: 'Are you sure you want to reset to default? unsaved changes will be lost.',
                    confirmBtnText: 'yes',
                    cancelBtnText: 'no',
                    confirmationType: 'success'
                  })
                    .then(confirmed => {
                        if(confirmed){
                            this.loading = true
                            this.LeadSources = []
                            this.DefaultLeadSources.forEach(lead => {
                            this.LeadSources.push(lead)
                            this.loading = false
                        });
                        }
                       
                    })
                }
        },
          ...mapActions({
        notifySuccess: 'alerts/notifySuccess',
        notifyError: 'alerts/notifyError',
        setLeadSources      : 'guest_cards/setLeadSources',
        }),
        saveLead(item){
            if(this.DefaultLeadSources[this.getIndexById(item.community_lead_sources_id,'default_source')].display_name == item.display_name){
                 item.status = "locked"
            }else{
                  item.status = "locked-edited"
            }

           
            return item
        },
        editMode(id, new_lead = false){
                if(!new_lead){
                    this.LeadSources[this.getIndexById(id,'lead_source')].status = "unlocked";
                }else{
                    this.LeadSources[this.getIndexById(id,'lead_source')].status = "new-lead";
                }
             
        },  
        updateLeadSourcesOrder(){
            this.LeadSources.forEach((lead,index) => {
                lead.display_order = index+1
            });

        },
      
        getIndexByOrder(order){
            let lead_index = this.LeadSources.findIndex(object => {
              return object.display_order === order;
            })

            return lead_index;
        },
         getIndexById(id,data_source){
            let lead_index

            if(data_source == "lead_source"){
                lead_index = this.LeadSources.findIndex(object => {
                    return object.community_lead_sources_id === id;
                })
            }else if(data_source == "default_source"){
                lead_index = this.DefaultLeadSources.findIndex(object => {
                    return object.community_lead_sources_id === id;
                })
            }

            return lead_index;
        },
        removeLead(index){
            this.LeadSources[index].status = "deleted"
            let last_order  = 1
           
            this.LeadSources.forEach((lead) => {
                
               
                console.log(last_order)
                lead.display_order = last_order
                 if(lead.status != 'deleted'){
                last_order++
                }
            });
           
        },
        save(){
            console.log(this.loading)
            this.loading = true
            console.log(this.loading)
            let payload = {
                            lead_sources : this.LeadSources,
                            action       : this.ActionOptions
                    }   
                   
                    this.$guestCardDataProvider.update('saveCommunityLeadSources',{id: this.activeCommunity.id, data:payload}).then((response) => {
                        if(response.success){
                                this.notifySuccess(response.message)
                                this.setLeadSources(response.data)
                        }else{
                                this.notifyError(response.message)
                        }
                        this.loading = false
                        console.log(this.loading)
                    })
           
        },
        cancel() {
            this.$emit('cancel');
            return null
        },
        loadCommunityLeadSources(){
               
                this.loading = true
                this.$guestCardDataProvider.get('initComunitiesLeadSources',{communities: JSON.stringify(this.community_ids)}).then((response) => {
                    this.LeadSources = response;
                    this.LeadSources.forEach((lead,index) => {
                         this.DefaultLeadSources.push({...lead, status:'locked'})
                            lead.index = index
                            lead.status = 'locked'
                    });
                   
                    this.loading = false
            }).catch()
            return null
        },
        listFromAnotherCommunty(){

            return null
        },
        finalizeNewLead(order){
           

            this.LeadSources[this.getIndexByOrder(order)].status   = "new-lead-locked"

           
        },
        addNewLead(){
            this.loading = true
            this.LeadSources.push({
                status                      :"new-lead",
                display_order               : this.LeadSources.length + 1,
                index                       : this.LeadSources.length,
                display_name                : null

            })
            this.loading = false
            setTimeout(this.scrollToNew, 500)
        },
        scrollToNew(){
            const container = this.$el.querySelector("#list");
            container.scrollTop = (container.scrollHeight + 40);
            this.$refs['new-lead-'+this.LeadSources.length].focus()
        },
        applyToCommunities(community_id = null){
            let result  = []
            if(Array.isArray(community_id)){
                    result = null
            }else{
                  result = null
            }
            return result;
        }
    }
   
}
</script>
<style scoped>
   .boxed{
       
       border: 1px solid gray;
       width: 400px;
       padding:10px;
       padding-left: 10px;
       margin-bottom: 10px;
   }
   .sort-position{
       float: left;
       margin-top: 0.4rem;
       margin-right: 5px;
   }
    .sort-close{

        float: right;
        margin-top: 0.9rem;
        margin-left: 10px;
        color: gray;
   }
   .no-scroll{
       overflow: hidden;
   }
    .edit-button{
        float: right;
        margin-left: 10px;
        color: gray;
    }
   .show-scroll{
        overflow: auto;
        padding: 12px;
        width: 100%;
      /*   border: gray 1px solid; */
        scroll-behavior: smooth !important;
   }
    .list-container{
        height: 600px !important;
        margin-top: 0px;
    }
     .justify-right{
        justify-content: right !important;
    }
    .justify-left{
        justify-content: left !important;
    }
    .control-container{
        float: right;
        justify-content: right;
    }

    .dropdown-container{
        /* margin-top: 5%; */
       /*  padding-left:5%;
        padding-right:5%; */
        margin-left: 100px;
    }

   *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #717171;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
  .new-marker{
      
    font-size: 10px;
    color: red;
  }
  .reset-to-default{
      margin-left: 25%;
  }
  .form-input.spacing-1-2.edit-text-box{
    width: 95%;
  }
  .form-input.spacing-1-2.new-text-box{
     width: 100%;
  }
</style>
